import React from "react";
import EnhancedContentSection from "./../../components/common/enhanced-content-section";
import PageTagging from "components/common/page-tagging/page-tagging";
import ButtonFx from "./../../components/common/button-fx";

const NewsSeparator = () => {
  return (
    <div className="text-center mt-4">
      &#128679;&#128679;&#128679;&#128679;&#128679;&#128679;&#128679;&#128679;&#128679;&#128679;&#128679;&#128679;&#128679;&#128679;&#128679;
    </div>
  );
};

const NewsItem = ({ date, title, imageName, imageAltText, children }) => {
  return (
    <div className="mt-4">
      <div className="row">
        <div className="col-md-6 col-12">
          <strong>{date}</strong>
          <h5 className="mt-2">{title}</h5>
          {children}
        </div>
        <div className="col-md-6 col-12">
          <img src={imageName} alt={imageAltText} className="image shadow" />
        </div>
      </div>
    </div>
  );
};

const News = () => {
  return (
    <EnhancedContentSection
      isBlocking={false}
      showPageTitle={true}
      pageTitle="Latest News"
      bgClass="sw-bg-std-grey"
    >
      <PageTagging
        title="Mr Traffic Hire | News"
        description="Keep up to date with our latest news from the Traffic Management Industry in Victoria"
      />
      <NewsSeparator />
      {/* <NewsItem date="" title="" imageName="/images/news/" imageAltText="">
        <p></p>
        <p></p>
      </NewsItem>
      <NewsSeparator /> */}
      <NewsItem
        date="November 3, 2022"
        title="More gear hitting the road!"
        imageName="/images/news/equipment.jpg"
        imageAltText="Equipment"
      >
        <p>
          With over 25 years combined experience, Mr Traffic Hire are ready to
          go above and beyond for your traffic management and equipment hire
          needs.
        </p>
        <p>Your problem, our solution.</p>
        <p>
          Available 24 hours a day, 7 days a week, our equipment is ready for
          your next project. 1300MRTRAFFIC
        </p>
      </NewsItem>
      <NewsSeparator />
      <NewsItem
        date="October 26, 2022"
        title="We have all the gear you need!"
        imageName="/images/news/fleet.jpg"
        imageAltText="Fleet"
      >
        <p>
          We have various types of vehicles to suit all of your Traffic
          Management needs.
        </p>
        <ul>
          <li>Scorpion MASH approved TMA’s</li>
          <li>Traffic Utes With VMS and Arrowboards</li>
          <li>POD trucks with VMS and sign racks</li>
          <li>TIP trucks</li>
          <li>Moving Trucks</li>
        </ul>
        <p>Call us 24/7 for any traffic hire needs. 1300MRTRAFFIC</p>
      </NewsItem>
      <NewsSeparator />
      <NewsItem
        date="August 2, 2022"
        title="&ldquo;THE RENTAL JOURNAL PODCAST - Chatting With Jamie Haitas: Growing through acquisitions&rdquo;"
        imageName="/images/news/podcast-aug-22.jpg"
        imageAltText="Padcast"
      >
        <p>
          Jamie Haitas is the General Manager at Mr Traffic Hire Australia in
          Melbourne, Australia. This is Jamie's second time on the podcast as we
          learn about two recent acquisitions he has made expanding into the
          general rental space.
        </p>
        <p>
          Check out this link to hear Jamie talking about his growth through
          acquisitions.
        </p>
        <ButtonFx
          className="mt-blue-mini-button mb-2"
          href=""
          onClick={() => window.open("https://youtu.be/08rKm6tdtP4")}
        >
          Listen Now
        </ButtonFx>
      </NewsItem>
      <NewsSeparator />
      <NewsItem
        date="March 8, 2022"
        title="Check out our new VMS Utes"
        imageName="/images/news/vms-ute.jpg"
        imageAltText="VMS Utes"
      >
        <p>
          Improve site safety with our VMS Utes providing an clear message to
          motorists.
        </p>
        <p>
          All are equipped with VMS Boards, sign racks, warning lights and arrow
          boards.
        </p>
        <p>Call us 24/7 for any traffic hire needs. 1300 MRTRAFFIC</p>
      </NewsItem>
      <NewsSeparator />
      <NewsItem
        date="November 26, 2021"
        title="Looking for a tipper?"
        imageName="/images/news/tipper-news.jpg"
        imageAltText="Portaboom"
      >
        <p>
          Come check out our 2T Tip truck which can be driven on a car license.
          Our newest addition to the fleet.
        </p>
        <p>Call us 24/7 for any traffic hire needs. 0401 292 921</p>
      </NewsItem>
      <NewsSeparator />
      <NewsItem
        date="September 9, 2021"
        title="Portaboom"
        imageName="/images/news/portaboom.jpg"
        imageAltText="Portaboom"
      >
        <p>
          Mr Traffic is now supplying PORTABOOM. Improve the safety of your
          worksite with PORTABOOM.
        </p>
        <p>Call us 24/7 for any traffic hire needs. 0401 292 921</p>
      </NewsItem>
      <NewsSeparator />
      <NewsItem
        date="August 3, 2021"
        title="MASH Approved TMA’s"
        imageName="/images/news/tma-2.jpg"
        imageAltText="Equipment hire"
      >
        <p>
          2 x Brand new mash approved TMA’s available now for hire! Australia
          wide!
        </p>{" "}
        <p>
          After hours - no problem. Call us 24/7 for any traffic hire needs.
          0401 292 921
        </p>
      </NewsItem>
      <NewsSeparator />

      <NewsItem
        date="March 31, 2021"
        title="Check out our brand new utes. They are looking great all shiny and new..."
        imageName="/images/news/utes-2.jpg"
        imageAltText="Traffic Management hire"
      >
        <p>
          Brand new traffic utes ready to be shipped to SA for a long term
          project. Please yell out if you need any extra vehicles we have quite
          the fleet!{" "}
        </p>
        <p>
          For more information regarding traffic equipment hire please contact
          me directly on jamie@mrtraffichire.com.au or 0401 292 921
        </p>
      </NewsItem>
      <NewsSeparator />
      <NewsItem
        date="January 27, 2021"
        title="&ldquo;The ups and downs of my hire journey over the last 13 years&rdquo;"
        imageName="/images/news/podcast.jpg"
        imageAltText="Padcast"
      >
        <p>
          Jamie opens up about some of the struggles he went through early in
          his career from over working and putting too much pressure on himself.
        </p>
        <p>
          Check out this link to hear Jamie talking about his path to Mr Traffic
          Hire.
        </p>
        <ButtonFx
          className="mt-blue-mini-button mb-2"
          href=""
          onClick={() =>
            window.open(
              "https://www.therentaljournal.com/chattingwithjamiehaitas"
            )
          }
        >
          Listen Now
        </ButtonFx>
      </NewsItem>
      <NewsSeparator />
      <NewsItem
        date="November 19, 2020"
        title="Lovely day for a drive to Ararat! Mr Traffic goes Anywhere, Anytime."
        imageName="/images/news/news-ararat.jpg"
        imageAltText="Traffic Management hire"
      >
        <p>
          Arrow boards, traffic lights and VMS. All available at Mr Traffic
          Hire.
        </p>
        <p>
          For more information regarding traffic equipment hire please contact
          me directly on jamie@mrtraffichire.com.au or 0401 292 921
        </p>
      </NewsItem>
      <NewsSeparator />
      <NewsItem
        date="October 9, 2020"
        title="First cab off the rank..."
        imageName="/images/news/vehicle.jpg"
        imageAltText="Traffic Management ute hire"
      >
        <p>Keep your eyes peeled for these babies make sure you say hello.</p>
        <p>
          A huge thank you to all of our loyal supporters as this dream couldn’t
          have been achieved without you &#128153;&#128153;
        </p>
      </NewsItem>
      <NewsSeparator />
      <NewsItem
        date="September 3, 2020"
        title="Urgent delivery to Mildura"
        imageName="/images/news/delivery.jpg"
        imageAltText="delivery"
      >
        <p>
          Customer calls for an urgent delivery of 4 x Arrow boards to Mildura,
          Mr Traffic made it happen!
        </p>
        <p>
          Arrow boards, traffic lights and VMS. All available at Mr Traffic Hire
        </p>
        <p>
          For more information regarding traffic equipment hire please contact
          me directly at jamie@mrtraffichire.com.au or 0401 292 921
        </p>
      </NewsItem>
      <NewsSeparator />
    </EnhancedContentSection>
  );
};

export default News;
