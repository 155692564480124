export const citiwests = [
  { name: "tma.jpg" },
  { name: "highway-truck.jpg" },
  { name: "traffic-utes.jpg" },
  { name: "travel-times.jpeg" },
  { name: "arrow-board.jpg" },
  { name: "portable-traffic-lights.jpg" },
  { name: "variable-speed-limit-signs.jpg" },
  { name: "truck-mounted-attenuators.jpg" },
  { name: "vms-boards.jpg" },
  { name: "vms-ute.jpg" },
];
